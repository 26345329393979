import { useCallback, useState } from "react";
import { Center } from "./components/Center";
//import Obfuscate from "react-obfuscate"
import { Email } from "react-obfuscate-email"
//import { SocialIcon } from "./components/SocialIcon";
import type { made } from "./types";
const profileUrls = new URL("./wackery.png?as=webp", import.meta.url).href;
const mades: made[] = [
    {
        title: "insberr/schedule-personalizer",
        desc: "A schedule app for BHS students",
        link: new URL("https://github.com/insberr/schedule-personalizer")
    },
    {
        "title": "ddlc-custom-backgrounds",
        desc: "A mod for DDLC+ which allows the use of custom backgrounds",
        link: new URL("https://github.com/webmsgr/ddlc-custom-backgrounds")
    },
    {
        title: "insberr/astary",
        desc: "js pathfinding library that uses nodes & edges instead of a 2d grid.",
        link: new URL("https://github.com/insberr/astary")
    }
]
const socials: made[] = [
    {
        title: "Github",
        desc: "webmsgr",
        link: new URL("https://github.com/webmsgr")
    },
    {
        title: "Discord",
        desc: "Wackery#4753"
    },
    {
        title: "Minecraft",
        desc: "TheWackery (old account wbnc was lost to the migration)"
    },
    /*{
        title: "Email",
        desc: <Email email="contact@wackery.com">Hover</Email>
    }*/
]
const mtop = { marginTop: "3em"}
export function App() {
    const [spin, _sp] = useState(false)
    const toggleSpin = useCallback(() => { _sp((s) => !s) }, [_sp])
    return (<Center>
        <img src={profileUrls} onClick={toggleSpin} className={spin ? "spin" : ""} style={{"marginTop": "3em", "borderRadius":"50%"}} />
        <p style={{...mtop, "fontWeight":"600", "fontSize": "1.5rem", "lineHeight": "2rem"}}>Wackery</p>
        <p style={{...mtop, "fontStyle":"italic"}}>Certified™ Professional™ Idiot™</p>
        <p style={mtop}>Hi, I'm Wackery. I write code that sometimes works.</p>
        <hr style={mtop} />
        <p style={mtop}>Things I've worked on: </p>
        <div>
        {mades.map((md) => {
            return <div key={md.title}><a href={md.link?.href}>{md.title}</a>: {md.desc}</div>
        })}
        </div>
        <hr style={mtop} />
        <p style={mtop}>Socials: </p>
        {socials.map((md) => {
            return <div key={md.title}><a href={md.link?.href}>{md.title}</a>: {md.desc}</div>
        })}
        <hr style={mtop} />
    </Center>)

}
