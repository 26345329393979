//import { createBootstrapComponent } from 'react-bootstrap/esm/ThemeProvider';
import {createRoot} from 'react-dom/client';
import { App } from './App'
import eruda from './eruda';
const ele = document.getElementById("app")
if (!ele) {
    console.error("what")
    throw new Error("what")
}
const root = createRoot(ele)
eruda(()=>root.render(<App />));